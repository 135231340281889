
const routerAuth = (router) => {
  // const router = vm.$router;
  router.beforeEach((to, from, next) => {
    debugger
    const isAuthenticated = window.auth?.isAuthenticated
    if (to.path.indexOf("/widgets/") == 0) {
      return next();
    } else if (to.path == "/authentication/log-in") {
      return next();

    }

    if (to.path == "/authentication/log-in") {
      return next();
    }

    if (isAuthenticated) {

      next();
    } else {

      return next({ path: "/authentication/log-in", query: { to: to } })
    }
  })
}

export default routerAuth;
