import MenuUtil from "@/common-lib/utils/MenuUtil";
let permission = {
  componentUpdated(el, binding, vnode) {
    const { value } = binding
    let menuId = ""
    const action = value.action;
    if (value.menuId != null) {
      menuId = value.menuId;
    } else {
      menuId = window.auth.menuId;
    }
    const store = vnode.context.$store;
    let permit = isPermmit(value.menuId, value.action);
    if (!permit) {
      el.parentNode && el.parentNode.removeChild(el)
    }

    return;

  },
  inserted(el, binding, vnode) {
    const { value } = binding
    if (window.auth == null) return;
    let menuId = ""
    const action = value.action;
    if (value.menuId != null) {
      menuId = value.menuId;
    } else {
      menuId = window.auth.menuId;
    }
    const store = vnode.context.$store;
    let permit = isPermmit(value.menuId, value.action);
    if (!permit) {
      el.parentNode && el.parentNode.removeChild(el)
    }

    return;

  },
  mounted() {
    console.log("mounted")
  },
  updated() {
    console.log("updated")
  },

}
const isPermmit = (menuName, action) => {
  let app = window.auth.selectedAppItem;
  const menuIdsActions = window.auth.menuIdsActions;
  const parentPermission = (menu, action) => {
    if (menu.parent != null) {
      if (menuIdsActions[menu.parent.name + ":All"]) {
        return true;
      } else if (menuIdsActions[menu.parent.name + ":" + action]) {
        return true;
      } else if (menuIdsActions[app.name + ":All"]) {
        return true;
      } else if (menuIdsActions[app.name + ":" + action]) {
        return true;
      } else {
        return parentPermission(menu.parent, action);

      }
      // }else{
      //   return false;
    }
  }
  if (menuIdsActions["root:All"]) {
    return true;
  } else if (menuIdsActions["root:" + action]) {
    return true;
  } else if (menuIdsActions[menuName + ":" + action]) {
    return true;
  } else if (menuIdsActions[app.name + ":All"]) {
    return true;
  } else if (menuIdsActions[app.name + ":" + action]) {
    return true;
  }

  let items = window.auth.selectedAppItems;
  let item = MenuUtil.findItem(items, menuName);
  if (item.permitted != null && item.permitted) return true;
  if (item.parent == null) return false;
  return parentPermission(item, action);
}

const install = function (Vue) {
  Vue.directive('permission', permission)
}
permission.install = install
export default permission
