<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
import '@/styles/overrides.sass'
import MessageHandler from "@/common-lib/MessageHandler.vue";

export default {
  name: 'App',
  mixins: [MessageHandler]
  ,
  created() {
    // window.addEventListener( 'message', this.receiveMessageFromParent );
  },
  methods: {
    // receiveMessageFromParent(e){
    //   if(e.data==null) return;
    //   if( e.data.cmd=='i18n'){
    //     this.$root.$i18n.locale = e.data.value;
    //   }else if(e.data.cmd=='auth'){
    //     this.$store.state.auth.token.accessToken = e.data.value.accessToken;
    //     this.$store.state.auth.isAuthenticated = e.data.value.isAuthenticated;
    //     this.$store.state.auth.user = e.data.value.user;
    //
    //   }
    // }
  }
};
</script>
<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
