import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import './plugins'
import i18n from './i18n'
import VueDayjs from 'vue-dayjs-plugin'

Vue.use(VueDayjs);
Vue.config.productionTip = false
import routerAuth from "@/common-lib/RouterAuth";
routerAuth(router)

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


