import Vue from "vue";
import VueRouter from "vue-router";

// import beforeAuth from "@/common-lib/RouterAuth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */
        "@/layouts/page/Index"
      ),
    children: [
      {
        path: "/plotly",
        name: "Plotly",
        component: () =>
          import(
            /* webpackChunkName: "views-dashboard" */
            "@/views/chart/Plotly"
          )
      },
      {
        path: "/widgets",
        name: "Widgets",
        component: () =>
          import(
            /* webpackChunkName: "views-dashboard" */
            "@/widgets/Widgets"
          )
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "views-dashboard" */
            "@/views/Dashboard"
          )
      },
      {
        path: "/grid-system",
        name: "GridSystem",
        component: () =>
          import(
            /* webpackChunkName: "views-grid-system" */
            "@/views/GridSystem"
          )
        // beforeEnter: beforeAuth(true),
      },
      {
        path: "/grid-list-page",
        name: "GridListPage",
        component: () =>
          import(
            /* webpackChunkName: "views-grid-list-page" */
            "@/views/GridListPage"
          )
        // beforeEnter: beforeAuth(true),
      },
      {
        path: "/breakpoints",
        name: "Breakpoints",
        component: () =>
          import(
            /* webpackChunkName: "views-breakpoints" */
            "@/views/Breakpoints"
          )
        // beforeEnter: beforeAuth(true),
      },
      {
        path: "/typography",
        name: "Typography",
        component: () =>
          import(
            /* webpackChunkName: "views-typography" */
            "@/views/Typography"
          )
      },
      {
        path: "/tables/app-table",
        name: "AppTables",
        component: () =>
          import(
            /* webpackChunkName: "views-app-tables" */
            "@/views/table/AppTables"
          )
      },
      {
        path: "/tables/basic-table",
        name: "BasicTables",
        component: () =>
          import(
            /* webpackChunkName: "views-basic-tables" */
            "@/views/table/BasicTables"
          )
      },
      {
        path: "/forms/validation-form",
        name: "ValidationForm",
        component: () =>
          import(
            /* webpackChunkName: "views-validation-forms" */
            "@/views/form/ValidationForms"
          )
      },
      {
        path: "/forms/app-form",
        name: "AppForm",
        component: () =>
          import(
            /* webpackChunkName: "views-app-forms" */
            "@/views/form/AppForms"
          )
      },
      {
        path: "/buttons",
        name: "Buttons",
        component: () =>
          import(
            /* webpackChunkName: "views-buttons" */
            "@/views/Buttons"
          ),
        props: route => ({ id: route.params.id, query: route.query }),
      },
      {
        path: "/icons",
        name: "Icons",
        component: () =>
          import(
            /* webpackChunkName: "views-icons" */
            "@/views/Icons"
          )
      },
      {
        path: "/multi-lang",
        name: "multiLang",
        component: () =>
          import(
            /* webpackChunkName: "views-multiLangs" */
            "@/views/MultiLang"
          )
      },
      {
        path: "/menu-manager",
        name: "MenuManager",
        component: () =>
          import(
            /* webpackChunkName: "views-multiLangs" */
            "@/views/menu/MenuManager"
          )
      },
      {
        path: "/sortable",
        name: "Sortable",
        component: () =>
          import(
            /* webpackChunkName: "views-multiLangs" */
            "@/views/sortable/Sortable"
          )
      },
      {
        path: "/dashboard-editor",
        name: "DashboardEditor",
        component: () =>
          import(
            /* webpackChunkName: "views-multiLangs" */
            "@/views/dashboard/DashboardEditor"
          )
      }
    ]
  },
  {
    path: "/authentication/log-in",
    name: "LogIn",
    component: () =>
      import(
        /* webpackChunkName: "views-sign-in" */
        "@/common-lib/LogIn"
      )
  },
  {
    path: "/page",
    component: () =>
      import(
        /* webpackChunkName: "layouts-page-index" */
        "@/layouts/page/Index"
      ),
    children: [
      {
        path: "product-list",
        name: "ProductList",
        component: () =>
          import(
            /* webpackChunkName: "views-product-list" */
            "@/views/page/ProductList"
          )
      }
    ]
  },
  {
    path: "/widgets",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */
        "@/layouts/page/Index"
      ),
    children: [
      {
        path: "/widgets/echart-widget",
        name: "eChartWidget",
        component: () =>
          import(
            /* webpackChunkName: "views-sign-in" */
            "@/widgets/echart/EchartWidget"
          )
      },
      {
        path: "/widgets/config/echart-widget",
        name: "eChartWidgetConfig",
        component: () =>
          import(
            /* webpackChunkName: "views-sign-in" */
            "@/widgets/echart/EchartWidgetConfig"
          )
      },
      {
        path: "/widgets/eqp-param-health",
        name: "eqpParamHealthWidget",
        component: () => import("@/widgets/eqpParamHealth/EqpParamHealthWidget")
      },
      {
        path: "/widgets/eqp-alarm",
        name: "eqpAlarmWidget",
        component: () => import("@/widgets/eqpAlarm/EqpAlarmWidget")
      },
      {
        path: "/widgets/spc-rule-trend",
        name: "spcRuleTrend",
        component: () => import("@/widgets/spcRuleTrend/SPCRuleTrendWidget")
      },
      {
        path: "/widgets/eqp-process-status",
        name: "eqpProcessStatus",
        component: () =>
          import("@/widgets/eqpProcessStatus/EqpProcessStatusWidget")
      },
      {
        path: "/widgets/eqp-param-trend",
        name: "eqpParamTrend",
        component: () => import("@/widgets/eqpParamTrend/EqpParamTrendWidget")
      },
      {
        path: "/widgets/eqp-param-trend-boxplot",
        name: "eqpParamTrendBoxplot",
        component: () =>
          import("@/widgets/eqpParamTrendBoxPlot/EqpParamTrendBoxPlotWidget")
      },
      {
        path: "/widgets/sample-widget",
        name: "sampleWidget",
        component: () =>
          import(
            /* webpackChunkName: "views-sign-in" */
            "@/widgets/sampleWidget/SampleWidget"
          )
      },
      {
        path: "/widgets/config/sample-widget",
        name: "sampletWidgetConfig",
        component: () =>
          import(
            /* webpackChunkName: "views-sign-in" */
            "@/widgets/sampleWidget/SampleWidgetConfig"
          )
      }
    ]
  }
];
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
