

const findItem=(items,id)=>{
  for(let i=0;i<items.length;i++){
    if(items[i].id !=null && items[i].id==id){
      return items[i];
    }else if(items[i].items!=null ){
      let findChild = findItem(items[i].items,id);
      if(findChild!=''){
        return findChild;
      }
    }
  }
  return '';
};
const findFirstLinkItem=(items)=>{
  if(items==null) return ''

  for(let i=0;i<items.length;i++){
    if(items[i].to !=null && items[i].to.length>0){
      return items[i];
    }else if(items[i].items!=null ){
      let findChild = findFirstLinkItem(items[i].items);
      if(findChild!=''){
        return findChild;
      }
    }
  }
  return '';
};
const findAppByChildId=(items,id)=>{
  for(let i=0;i<items.length;i++){
    let item = items[i];
    if(findItem(item.items,id)!=''){
      return item;
    }
  }
  return '';
}
const findAppById=(items,id)=>{
  for(let i=0;i<items.length;i++){
    let item = items[i];
    if(item.id == id){
      return item;
    }
  }
  return '';
}

const MenuUtil = { findItem ,findFirstLinkItem,findAppByChildId,findAppById}

export default MenuUtil;
